import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useScreenSize } from 'hooks/useScreenSize';
import Image from 'next/image';
import React, { FC, useRef } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperClass } from 'swiper/types';
import { LeftNavIconPure, RightNavIconPure } from './GoogleReviews/CarouselNav';
import ButtonBase from '@mui/material/ButtonBase';

const Title = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    position: 'relative',
    paddingBottom: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '2.125rem',
    maxWidth: 400,
    '&::after': {
      position: 'absolute',
      left: 0,
      bottom: -5,
      content: '""',
      background: 'red',
      height: 4,
      width: theme.spacing(15),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8rem',
      '&::after': {
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    maxWidth: 300,
    color: theme.palette.grey[700],
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto',
    },
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  background: 'transparent',
  maxWidth: 400,
  alignSelf: 'flex-end',
  [theme.breakpoints.down('sm')]: {
    alignSelf: 'center',
  },
}));

const ColumnInner = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.down('xs')]: {
    padding: 0,
  },
}));

const CarouselItem = styled(Grid)(({ theme }) => ({
  minHeight: 580,
  [theme.breakpoints.down('xs')]: {
    minHeight: 650,
  },
}));

const StyledSwiper = styled(Swiper)(({ theme }) => ({
  '& .swiper-pagination-bullet ': {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
}));

interface Step {
  title: string;
  description: string;
  img: string;
  width: number;
  height: number;
  widthMobile: number;
  heightMobile: number;
}

const STEPS: Step[] = [
  {
    title: 'Create an account and fill in your information',
    description:
      'Sign up with your email and zipcode. We need your credit tier to provide accurate prices',
    img: '/img/about_us/how-it-works-step-1.webp',
    width: 350,
    height: 420,
    widthMobile: 250,
    heightMobile: 300,
  },
  {
    title: 'Find your vehicle',
    description:
      'Search for vehicles by the year, make, model or even option packages you want',
    img: '/img/about_us/how-it-works-step-2.webp',
    width: 350,
    height: 425,
    widthMobile: 250,
    heightMobile: 300,
  },
  {
    title: 'Apply incentives and rebates',
    description:
      'Answer some simple questions to take advantage of incentives and rebates',
    img: '/img/about_us/how-it-works-step-3.webp',
    width: 350,
    height: 365,
    widthMobile: 250,
    heightMobile: 265,
  },
  {
    title: 'Set your leasing terms and get a great deal',
    description:
      'Whether you choose to lease or finance, adjust your term length, mileage, down payment, and other conditions to find the perfect plan. Enjoy flexible options tailored to your needs and drive away with confidence.',
    img: '/img/about_us/how-it-works-step-4.webp',
    width: 320,
    height: 430,
    widthMobile: 220,
    heightMobile: 300,
  },
  {
    title: 'Explore Leasing, Financing, or Bidding Options',
    description:
      'Discover your perfect vehicle through leasing, financing, or by placing a bid. Enjoy flexibility and great deals tailored to your needs.',
    img: '/img/about_us/how-it-works-step-5.webp',
    width: 350,
    height: 460,
    widthMobile: 250,
    heightMobile: 320,
  },
];

type HowItWorksCarouselProps = {
  steps?: Step[];
};

const ReStyledSwiper = styled(StyledSwiper)({
  '& .swiper-button-prev, & .swiper-button-next': {
    '&::after': {
      content: '""',
      display: 'none',
    },
  },
  '& .swiper-button-prev': {
    left: 'var(--swiper-navigation-sides-offset, 8px)',
  },
  '& .swiper-button-next': {
    right: 'var(--swiper-navigation-sides-offset, 8px)',
  },
});

export const HowItWorksCarousel: FC<HowItWorksCarouselProps> = ({
  steps = STEPS,
}) => {
  const { screenSize } = useScreenSize();
  const isNavigationVisible = !['xs', 'sm'].includes(screenSize);

  const swiperRef = useRef<SwiperClass>(null);

  return (
    <Box pb={1}>
      <ReStyledSwiper
        loop
        style={{
          marginBottom: 40,
          paddingBottom: 40,
          '--swiper-navigation-size': '80px',
        }}
        spaceBetween={80}
        pagination={{
          clickable: true,
        }}
        onBeforeInit={(swiper: SwiperClass) => {
          swiperRef.current = swiper;
        }}
        modules={[Navigation, Pagination]}
      >
        <div className='swiper-wrapper'>
          {steps.map((step, index) => (
            <SwiperSlide
              key={step.title}
              style={{
                marginBottom: 50,
              }}
            >
              <CarouselItem container spacing={2}>
                <Grid item xs={true}>
                  <ColumnInner>
                    <StyledCard elevation={3}>
                      <Image
                        src={step.img}
                        width={
                          isNavigationVisible ? step.width : step.widthMobile
                        }
                        height={
                          isNavigationVisible ? step.height : step.heightMobile
                        }
                        alt={`How It Works Step ${index}`}
                      />
                    </StyledCard>
                  </ColumnInner>
                </Grid>
                <Grid item xs={true}>
                  <ColumnInner>
                    <Title variant='h3' gutterBottom>
                      {step.title}
                    </Title>
                    <Description variant='body1' gutterBottom>
                      {step.description}
                    </Description>
                  </ColumnInner>
                </Grid>
              </CarouselItem>
            </SwiperSlide>
          ))}
        </div>
        {isNavigationVisible ? (
          <>
            <div
              className='swiper-button-prev'
              onClick={() => {
                swiperRef.current?.slidePrev();
              }}
            >
              <ButtonBase>
                <LeftNavIconPure />
              </ButtonBase>
            </div>
            <div
              className='swiper-button-next'
              onClick={() => {
                swiperRef.current?.slideNext();
              }}
            >
              <ButtonBase>
                <RightNavIconPure />
              </ButtonBase>
            </div>
          </>
        ) : null}
      </ReStyledSwiper>
    </Box>
  );
};

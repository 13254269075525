import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DividerLeftMui5, DividerMui5 } from 'components/atoms/Divider';
import { NextLinkMui5 } from 'components/atoms/NextLink';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import Image from 'next/image';
import { BRAND_NAME } from 'shared/utils/constants';
import { grey } from 'theme/colors';
import { Subheader } from './components/common';
import { FC } from 'react';

const ImgWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const AboutUs: FC = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Wrapper>
      <Grid container spacing={4}>
        <Grid item md={6} sm={12} xs={12}>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Box color={grey[700]} mt={3} mb={-1}>
              <Subheader variant='h2' color='textSecondary'>
                {BRAND_NAME}: simplifying car leasing & financing
              </Subheader>
            </Box>
            <Typography variant='h3' gutterBottom>
              Drive off with the best deal on the market
            </Typography>
            <DividerLeftMui5 />
            <Box color={grey[700]} mb={4} maxWidth={450}>
              <Typography variant='body2' gutterBottom>
                We make car leasing easy, transparent, and—best of all — free
                from dealership drama. <br /> Browse top deals, apply rebates,
                and choose between leasing or financing — all online. <br /> We
                bring you the best deals across NY, NJ, PA, CT, MD, and CA —
                whether you’re after a luxury ride or affordable financing,
                we’re here to find the car that fits your needs.
              </Typography>
            </Box>
            <Box maxWidth={220}>
              <StyledButtonMui5
                fullWidth
                variant='contained'
                color='primary'
                component={NextLinkMui5}
                href='/about'
                size='small'
              >
                Learn more
              </StyledButtonMui5>
            </Box>
          </Box>
          <Box sx={{ display: { sm: 'none', xs: 'block' } }}>
            <Box
              color={grey[700]}
              mb={-1}
              display='flex'
              justifyContent='center'
            >
              <Subheader color='textSecondary'>About us</Subheader>
            </Box>
            <Typography variant='h4' gutterBottom align='center'>
              Facilitating trust, transparency & fairness
            </Typography>
            <DividerMui5 />
            <Box color={grey[700]} mb={4}>
              <Typography variant='body2' gutterBottom align='center'>
                {BRAND_NAME} was designed to eliminate the discomfort of
                haggling for hours at the dealership by empowering consumers to
                bid on their lease payment instead. Local dealers post inventory
                at unheard of prices, we apply all rebates and incentives and
                you simply bid, win and drive.
              </Typography>
            </Box>
            <Box maxWidth={220} display='flex' margin='0 auto'>
              <StyledButtonMui5
                fullWidth
                variant='contained'
                color='primary'
                component={NextLinkMui5}
                href='/about'
                size='small'
              >
                Learn more
              </StyledButtonMui5>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <ImgWrapper>
            <Image
              alt='Happy people'
              width={sm ? 350 : 550}
              height={sm ? 250 : 450}
              src='/img/home-about-us.webp'
            />
          </ImgWrapper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

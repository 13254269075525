import { FC } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons/faCircleCheck';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { SmallDividerMui5 } from 'components/atoms/Divider';
import Image from 'next/image';

const Wrapper = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: 12,
  height: '100%',
}));

const CardButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'block',
  borderRadius: 12,
  padding: theme.spacing(0, 2),
  border: '1px solid transparent',
  background: theme.palette.common.white,
  borderColor: selected ? theme.palette.primary.main : 'none',
}));

const SelectedIcon = styled(StyledFontAwesomeIconMui5)(({ theme }) => ({
  color: theme.palette.primary.main,
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

const TextWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(-3.5),
  textTransform: 'initial',
}));

const Icon = styled(StyledFontAwesomeIconMui5)(({ theme }) => ({
  height: 10,
  color: theme.palette.secondary.main,
  padding: '0 1px',
}));

export enum SearchOptionMedia {
  SUV,
  Truck,
  Sedan,
  Coupe,
  Minivan,
  Affordable,
  MidRange,
  UpperRange,
  Luxury,
  CarPlay,
  HeatedSeats,
  HeatedSteeringWheel,
  LeatherSeats,
  Sunroof,
  ThirdRowSeating,
  KeylessEntry,
  KeylessStart,
  Acura,
  Audi,
  Buick,
  Cadillac,
  Chevrolet,
  Chrysler,
  Dodge,
  Ford,
  GMC,
  Honda,
  Hyundai,
  Jeep,
  KIA,
  Lincoln,
  Mazda,
  Mercedes,
  Mitsubishi,
  Nissan,
  RAM,
  Toyota,
  Volkswagen,
  Volvo,
}

interface Image {
  src: string;
  size?: 'sm' | 'md';
}

interface Media {
  image?: Image;
  icons?: IconDefinition[];
}

const mediaMap: Record<SearchOptionMedia, Media> = {
  [SearchOptionMedia.SUV]: { image: { src: '/img/body_types/suv.webp' } },
  [SearchOptionMedia.Truck]: { image: { src: '/img/body_types/truck.webp' } },
  [SearchOptionMedia.Sedan]: { image: { src: '/img/body_types/sedan.webp' } },
  [SearchOptionMedia.Coupe]: { image: { src: '/img/body_types/coupe.webp' } },
  [SearchOptionMedia.Minivan]: {
    image: { src: '/img/body_types/minivan.webp' },
  },
  [SearchOptionMedia.Affordable]: { icons: [faDollarSign] },
  [SearchOptionMedia.MidRange]: { icons: [faDollarSign, faDollarSign] },
  [SearchOptionMedia.UpperRange]: {
    icons: [faDollarSign, faDollarSign, faDollarSign],
  },
  [SearchOptionMedia.Luxury]: {
    icons: [faDollarSign, faDollarSign, faDollarSign, faDollarSign],
  },
  [SearchOptionMedia.CarPlay]: {
    image: { src: '/img/features/apple-car-play.svg', size: 'sm' },
  },
  [SearchOptionMedia.HeatedSeats]: {
    image: { src: '/img/features/heated-seats.svg', size: 'sm' },
  },
  [SearchOptionMedia.HeatedSteeringWheel]: {
    image: { src: '/img/features/heated-steering-wheel.svg', size: 'sm' },
  },
  [SearchOptionMedia.LeatherSeats]: {
    image: { src: '/img/features/leather.svg', size: 'sm' },
  },
  [SearchOptionMedia.Sunroof]: {
    image: { src: '/img/features/sunroof.svg', size: 'sm' },
  },
  [SearchOptionMedia.ThirdRowSeating]: {
    image: { src: '/img/features/third-row-seats.svg', size: 'sm' },
  },
  [SearchOptionMedia.KeylessEntry]: {
    image: { src: '/img/features/keyless-entry.svg', size: 'sm' },
  },
  [SearchOptionMedia.KeylessStart]: {
    image: { src: '/img/features/keyless-start.svg', size: 'sm' },
  },
  [SearchOptionMedia.Acura]: {
    image: { src: '/img/brands/logo/Acura.svg', size: 'sm' },
  },
  [SearchOptionMedia.Audi]: {
    image: { src: '/img/brands/logo/Audi.svg', size: 'sm' },
  },
  [SearchOptionMedia.Buick]: {
    image: { src: '/img/brands/logo/Buick.svg', size: 'sm' },
  },
  [SearchOptionMedia.Cadillac]: {
    image: { src: '/img/brands/logo/Cadillac.svg', size: 'sm' },
  },
  [SearchOptionMedia.Chevrolet]: {
    image: { src: '/img/brands/logo/Chevrolet.svg', size: 'sm' },
  },
  [SearchOptionMedia.Chrysler]: {
    image: { src: '/img/brands/logo/Chrysler.svg', size: 'sm' },
  },
  [SearchOptionMedia.Dodge]: {
    image: { src: '/img/brands/logo/Dodge.svg', size: 'sm' },
  },
  [SearchOptionMedia.Ford]: {
    image: { src: '/img/brands/logo/Ford.svg', size: 'sm' },
  },
  [SearchOptionMedia.GMC]: {
    image: { src: '/img/brands/logo/GMC.svg', size: 'sm' },
  },
  [SearchOptionMedia.Honda]: {
    image: { src: '/img/brands/logo/Honda.svg', size: 'sm' },
  },
  [SearchOptionMedia.Hyundai]: {
    image: { src: '/img/brands/logo/Hyundai.svg', size: 'sm' },
  },
  [SearchOptionMedia.Jeep]: {
    image: { src: '/img/brands/logo/Jeep.svg', size: 'sm' },
  },
  [SearchOptionMedia.KIA]: {
    image: { src: '/img/brands/logo/KIA.svg', size: 'sm' },
  },
  [SearchOptionMedia.Lincoln]: {
    image: { src: '/img/brands/logo/Lincoln.svg', size: 'sm' },
  },
  [SearchOptionMedia.Mazda]: {
    image: { src: '/img/brands/logo/Mazda.svg', size: 'sm' },
  },
  [SearchOptionMedia.Mercedes]: {
    image: { src: '/img/brands/logo/Mercedes.svg', size: 'sm' },
  },
  [SearchOptionMedia.Mitsubishi]: {
    image: { src: '/img/brands/logo/Mitsubishi.svg', size: 'sm' },
  },
  [SearchOptionMedia.Nissan]: {
    image: { src: '/img/brands/logo/Nissan.svg', size: 'sm' },
  },
  [SearchOptionMedia.RAM]: {
    image: { src: '/img/brands/logo/RAM.svg', size: 'sm' },
  },
  [SearchOptionMedia.Toyota]: {
    image: { src: '/img/brands/logo/Toyota.svg', size: 'sm' },
  },
  [SearchOptionMedia.Volkswagen]: {
    image: { src: '/img/brands/logo/Volkswagen.svg', size: 'sm' },
  },
  [SearchOptionMedia.Volvo]: {
    image: { src: '/img/brands/logo/Volvo.svg', size: 'sm' },
  },
};

type SearchOptionCardProps = {
  media: SearchOptionMedia;
  title: string;
  titleSize?: 'md' | 'lg';
  onClick(): void;
  selected: boolean;
  hideTitle: boolean;
};

export const SearchOptionCard: FC<SearchOptionCardProps> = ({
  media,
  title,
  titleSize,
  selected,
  hideTitle,
  onClick,
}) => {
  const mediaObj = mediaMap[media];

  return (
    <Wrapper>
      <CardButton
        key={title}
        selected={selected}
        onClick={() => {
          onClick();
        }}
      >
        {selected && <SelectedIcon icon={faCircleCheck} />}
        {mediaObj?.icons && (
          <Box display='flex' justifyContent='center' pt={1} pb={4}>
            {mediaObj.icons.map((icon, index) => (
              <Icon icon={icon} key={index} />
            ))}
          </Box>
        )}
        {mediaObj?.image && (
          <Box
            mt={mediaObj.image.size === 'sm' ? 0.5 : -2}
            mb={mediaObj.image.size === 'sm' ? 3.5 : 0}
          >
            <Image
              src={mediaObj.image.src}
              alt={title}
              width={mediaObj.image.size === 'sm' ? 90 : 120}
              height={mediaObj.image.size === 'sm' ? 35 : 90}
            />
          </Box>
        )}
        <TextWrapper>
          <Typography
            sx={{
              fontWeight: 'bold',
            }}
            variant={titleSize === 'lg' ? 'body1' : 'body2'}
            align='center'
          >
            {!hideTitle ? title : ''}
          </Typography>
          <SmallDividerMui5 />
        </TextWrapper>
      </CardButton>
    </Wrapper>
  );
};

import { FC } from 'react';
import {
  GoogleBusinessAPIReview,
  GoogleBusinessAPIStarRating,
} from '../../../shared/types/googleReviews';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Image from 'next/image';
import { StarRatingComponent } from './StarRating';
import { RecursivePartial } from '../../../shared/types/common';

const MAX_STAR_RATTING_VALUE = 5;
const STAR_RATING_MAP: Record<GoogleBusinessAPIStarRating, number> = {
  [GoogleBusinessAPIStarRating.FIVE]: 5,
  [GoogleBusinessAPIStarRating.FOUR]: 4,
  [GoogleBusinessAPIStarRating.THREE]: 3,
  [GoogleBusinessAPIStarRating.TWO]: 2,
  [GoogleBusinessAPIStarRating.ONE]: 1,
  [GoogleBusinessAPIStarRating.STAR_RATING_UNSPECIFIED]: 0,
};

const ReviewBody = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 16),
  [theme.breakpoints.down('sm')]: {
    margin: 0,
  },
}));

const PhotoContainer = styled(Box)(() => ({
  boxSizing: 'content-box',
  width: '60px',
  height: '60px',
}));

type Props = { review: RecursivePartial<GoogleBusinessAPIReview> };

export const ReviewGalleryItem: FC<Props> = ({ review }) => {
  return (
    <Box pt={5} display='flex' justifyContent='center'>
      <ReviewBody>
        <Typography
          variant='body1'
          color='textSecondary'
          align='center'
          sx={{
            '&.MuiTypography-root': {
              whiteSpace: 'normal',
            },
          }}
        >
          {review.comment}
        </Typography>
        <Box pt={4} display='flex' justifyContent='center' alignItems='center'>
          <PhotoContainer pr={2}>
            <Image
              src={review.reviewer.profilePhotoUrl ?? '#'}
              alt={review.reviewer.displayName}
              width={64}
              height={64}
            />
          </PhotoContainer>
          <Box
            height='100%'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            flexWrap='wrap'
          >
            <Typography
              variant='body1'
              color='primary'
              align='left'
              gutterBottom
              sx={{
                '&.MuiTypography-root': {
                  lineHeight: '100%',
                  whiteSpace: 'break-spaces',
                  wordBreak: 'break-word',
                },
              }}
            >
              {review.reviewer.displayName}
            </Typography>
            <StarRatingComponent
              maxRating={MAX_STAR_RATTING_VALUE}
              rating={STAR_RATING_MAP[review.starRating]}
            />
          </Box>
        </Box>
      </ReviewBody>
    </Box>
  );
};

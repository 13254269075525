import { FC } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { DividerMui5 } from 'components/atoms/Divider';
import Image from 'next/image';
import { useScreenSize } from 'hooks/useScreenSize';
import { BRAND_NAME } from 'shared/utils/constants';
import { grey } from 'theme/colors';

const Icon = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  transition: 'filter 0.3s',
}));

const HowItWorksItem = styled('div')(() => ({
  textAlign: 'center',
  height: '100%',
}));

const Content = styled('div')(() => ({
  overflow: 'hidden',
}));

const StyledImage = styled(Image, {
  shouldForwardProp: (prop) => prop !== 'isBlured',
})<{ isBlured: boolean }>(({ isBlured }) => ({
  filter: isBlured ? 'blur(4px)' : '',
}));

const benefits = [
  {
    title: 'Lowest Prices & Flexible Financing',
    icon: '/img/icons/icon-lowest-prices.svg',
    alt: 'Prices',
    width: 106,
    height: 90,
  },
  {
    title: 'No hidden fees',
    icon: '/img/icons/icon-no-hidden-fees.svg',
    alt: 'Fees',
    width: 128,
    height: 90,
  },
  {
    title: 'Verified inventory',
    icon: '/img/icons/icon-verified-inventory.svg',
    alt: 'Verified Inventory',
    width: 138,
    height: 90,
  },
  {
    title: 'Fast and easy',
    icon: '/img/icons/icon-fast-easy.svg',
    alt: 'Fast and easy',
    width: 92,
    height: 90,
  },
];

export const Benefits: FC = () => {
  const { isSmallScreen, isExtraSmallScreen } = useScreenSize();

  const isCompact = isSmallScreen || isExtraSmallScreen;

  return (
    <>
      <Box
        color={grey[700]}
        mb={4}
        justifyContent='center'
        alignItems='center'
        display='flex'
        flexDirection='column'
      >
        <Typography
          variant={isCompact ? 'h4' : 'h3'}
          gutterBottom
          align='center'
        >
          {BRAND_NAME} Guarantees:
        </Typography>
        <DividerMui5 />
      </Box>
      <Content>
        <Grid container>
          {benefits.map((benefit) => (
            <Grid item lg={3} md={3} sm={6} xs={6} key={benefit.title}>
              <HowItWorksItem>
                <Icon>
                  <StyledImage
                    width={benefit.width}
                    height={benefit.height}
                    alt={benefit.alt}
                    src={benefit.icon}
                    isBlured={false}
                  />
                </Icon>
              </HowItWorksItem>
            </Grid>
          ))}
        </Grid>
      </Content>
    </>
  );
};

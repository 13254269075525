import { FC, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import Typography from '@mui/material/Typography';
import { lightThemeDataMui5 } from 'theme';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SearchOptionCard, SearchOptionMedia } from './SearchOptionCard';
import useMediaQuery from '@mui/material/useMediaQuery';

import 'swiper/css';

const CARD_HEIGHT = 100;
const CARD_WIDTH = 150;
const CARD_MARGIN = 8;
const VISIBLE_CARDS: ClassesProps['visibleCards'] = {
  xs: 2,
  sm: 3,
  md: 3,
  lg: 3,
  xl: 3,
};

type ClassesProps = {
  visibleCards?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  numberOfOptions?: number;
  fullWidth?: boolean;
};

const getTransformValue = (isVertical, isAlt) => {
  if (isVertical) {
    return 'translate3d(-50%, -50%, 0) rotate(270deg)';
  }

  if (isAlt) {
    return 'translate3d(50%, -50%, 0) rotate(90deg)';
  }

  return 'none';
};

const Title = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'isHorizontal' && prop !== 'isVertical' && prop !== 'isAlt',
})<{ isHorizontal: boolean; isVertical: boolean; isAlt: boolean }>(
  ({ theme, isHorizontal, isVertical, isAlt }) => ({
    '&.MuiTypography-root': {
      fontSize: 14,
      letterSpacing: 2,
      position: isHorizontal ? 'initial' : 'absolute',
      whiteSpace: 'nowrap',
      display: 'flex',
      marginLeft: theme.spacing(2),
      justifyContent: 'center',
      marginBottom: isHorizontal ? theme.spacing(1) : 0,
      top: isVertical || isAlt ? '50%' : '0',
      left: isVertical ? theme.spacing(1) : theme.spacing(0),
      right: isAlt ? theme.spacing(1) : theme.spacing(0),
      transform: getTransformValue(isVertical, isAlt),
    },
  }),
);

type BreakpointsProps = {
  xl: boolean;
  lg: boolean;
  md: boolean;
  sm: boolean;
  xs: boolean;
};

const getValueByBreakpoint = (breakpoints, visibleCards, multiplier) => {
  const breakpoint = Object.fromEntries(
    Object.entries(breakpoints).filter(([, val]) => val),
  );
  const keys = Object.keys(breakpoint);

  if (!keys.length) {
    return multiplier * visibleCards.xl;
  }

  const breakpointName = keys[keys.length - 1];

  return multiplier * visibleCards[breakpointName];
};

const HorizontalSwiperStyles = (
  visibleCards: ClassesProps['visibleCards'],
  fullWidth: boolean,
  breakpoints: BreakpointsProps,
) => ({
  width: fullWidth
    ? '100%'
    : getValueByBreakpoint(breakpoints, visibleCards, CARD_WIDTH + CARD_MARGIN),
  overflow: 'hidden',
  display: 'flex',
});

const VerticalSwiperStyles = (
  visibleCards: ClassesProps['visibleCards'],
  breakpoints: BreakpointsProps,
) => ({
  height: getValueByBreakpoint(
    breakpoints,
    visibleCards,
    CARD_HEIGHT + CARD_MARGIN,
  ),
  overflow: 'hidden',
});

const SwiperItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<ClassesProps>(({ fullWidth }) => ({
  margin: CARD_MARGIN / 2,
  lex: `0 0 ${CARD_WIDTH}px`,
  width: fullWidth ? 'auto' : CARD_WIDTH,
  height: CARD_HEIGHT,
}));

const ArrowButton = styled(IconButton, {
  shouldForwardProp: (prop) =>
    prop !== 'visibleCards' && prop !== 'numberOfOptions',
})<ClassesProps>(({ theme, visibleCards, numberOfOptions }) => ({
  borderRadius: 12,
  margin: theme.spacing(0.5),
  visibility: numberOfOptions > visibleCards.xl ? 'visible' : 'hidden',
  [theme.breakpoints.down('lg')]: {
    visibility: numberOfOptions > visibleCards.lg ? 'visible' : 'hidden',
  },
  [theme.breakpoints.down('md')]: {
    visibility: numberOfOptions > visibleCards.md ? 'visible' : 'hidden',
  },
  [theme.breakpoints.down('sm')]: {
    visibility: numberOfOptions > visibleCards.sm ? 'visible' : 'hidden',
  },
  [theme.breakpoints.down('xs')]: {
    visibility: numberOfOptions > visibleCards.xs ? 'visible' : 'hidden',
  },
}));

export interface SearchOptionItem {
  title: string;
  titleSize?: 'md' | 'lg';
  query: string;
  media: SearchOptionMedia;
  hideTitle?: boolean;
}

type SearchOptionListProps = {
  title: string;
  searchOptions: SearchOptionItem[];
  orientation?: 'horizontal' | 'vertical';
  altTitlePosition?: boolean;
  multiselect?: boolean;
  visibleCards?: ClassesProps['visibleCards'];
  fullWidth?: boolean;
  selectedOptions: Set<string>;
  onSelectedOptionsChange(selectedOptions: Set<string>): void;
};

export const SearchOptionList: FC<SearchOptionListProps> = ({
  title,
  searchOptions,
  orientation = 'vertical',
  altTitlePosition = false,
  multiselect = false,
  fullWidth = false,
  visibleCards = VISIBLE_CARDS,
  selectedOptions,
  onSelectedOptionsChange,
}) => {
  const [swiper, setSwiper] = useState(null);
  const isHorizontal = orientation === 'horizontal';
  const visibleCardsConfig = { ...VISIBLE_CARDS, ...visibleCards };
  const theme = useTheme();

  const xl = useMediaQuery(theme.breakpoints.down('xl'));
  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  const Breakpoints = {
    [lightThemeDataMui5.breakpoints.values.xs]: {
      height: (CARD_HEIGHT + CARD_MARGIN) * visibleCardsConfig.xs,
      slidesPerView: visibleCardsConfig.xs,
      enabled: searchOptions.length > visibleCardsConfig.xs,
    },
    [lightThemeDataMui5.breakpoints.values.sm]: {
      height: (CARD_HEIGHT + CARD_MARGIN) * visibleCardsConfig.sm,
      slidesPerView: visibleCardsConfig.sm,
      enabled: searchOptions.length > visibleCardsConfig.sm,
    },
    [lightThemeDataMui5.breakpoints.values.md]: {
      height: (CARD_HEIGHT + CARD_MARGIN) * visibleCardsConfig.md,
      slidesPerView: visibleCardsConfig.md,
      enabled: searchOptions.length > visibleCardsConfig.md,
    },
    [lightThemeDataMui5.breakpoints.values.lg]: {
      height: (CARD_HEIGHT + CARD_MARGIN) * visibleCardsConfig.lg,
      slidesPerView: visibleCardsConfig.lg,
      enabled: searchOptions.length > visibleCardsConfig.lg,
    },
    [lightThemeDataMui5.breakpoints.values.xl]: {
      height: (CARD_HEIGHT + CARD_MARGIN) * visibleCardsConfig.xl,
      slidesPerView: visibleCardsConfig.xl,
      enabled: searchOptions.length > visibleCardsConfig.xl,
    },
  };

  return (
    <Box mb={2} position='relative'>
      <Title
        variant='overline'
        align='center'
        color='textSecondary'
        isHorizontal={true}
        isVertical={false}
        isAlt={altTitlePosition}
      >
        {title}
      </Title>
      <Box
        display='flex'
        alignItems={
          isHorizontal ? 'center' : altTitlePosition ? 'flex-end' : 'flex-start'
        }
        justifyContent={isHorizontal ? 'center' : ''}
      >
        <Box
          justifyContent='center'
          width={fullWidth ? '100%' : 'auto'}
          display='flex'
          marginLeft={!isHorizontal && !altTitlePosition ? 4 : 0}
          marginRight={!isHorizontal && altTitlePosition ? 4 : 0}
          flexDirection={isHorizontal ? 'row' : 'column'}
        >
          <ArrowButton
            color='primary'
            size='small'
            visibleCards={visibleCardsConfig}
            numberOfOptions={searchOptions.length}
            onClick={() => {
              swiper?.slidePrev();
            }}
          >
            <StyledFontAwesomeIconMui5
              icon={isHorizontal ? faChevronLeft : faChevronUp}
              color='inherit'
              sx={{ color: 'inherit' }}
            />
          </ArrowButton>
          {isHorizontal ? (
            <Swiper
              watchOverflow
              loop
              breakpoints={Breakpoints}
              onSwiper={(swiper) => setSwiper(swiper)}
              direction={'horizontal'}
              style={HorizontalSwiperStyles(visibleCardsConfig, fullWidth, {
                xl,
                lg,
                md,
                sm,
                xs,
              })}
            >
              {searchOptions.map(({ media, title, titleSize, hideTitle }) => (
                <SwiperSlide key={title}>
                  <SwiperItem fullWidth={fullWidth}>
                    <SearchOptionCard
                      media={media}
                      title={title}
                      titleSize={titleSize}
                      selected={selectedOptions.has(title)}
                      hideTitle={hideTitle}
                      onClick={() => {
                        let newSelectedOptions = new Set(selectedOptions);
                        if (newSelectedOptions.has(title)) {
                          newSelectedOptions.delete(title);
                        } else if (!multiselect) {
                          newSelectedOptions = new Set([title]);
                        } else {
                          newSelectedOptions.add(title);
                        }
                        onSelectedOptionsChange(newSelectedOptions);
                      }}
                    />
                  </SwiperItem>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Swiper
              className={'swiper-vertical'}
              watchOverflow
              loop
              slidesPerView={4}
              breakpoints={Breakpoints}
              onSwiper={(swiper) => setSwiper(swiper)}
              direction={'vertical'}
              style={VerticalSwiperStyles(visibleCards, { xl, lg, md, sm, xs })}
            >
              {searchOptions.map(({ media, title, titleSize, hideTitle }) => (
                <SwiperSlide key={title}>
                  <SwiperItem fullWidth={fullWidth}>
                    <SearchOptionCard
                      media={media}
                      title={title}
                      titleSize={titleSize}
                      selected={selectedOptions.has(title)}
                      hideTitle={hideTitle}
                      onClick={() => {
                        let newSelectedOptions = new Set(selectedOptions);
                        if (newSelectedOptions.has(title)) {
                          newSelectedOptions.delete(title);
                        } else if (!multiselect) {
                          newSelectedOptions = new Set([title]);
                        } else {
                          newSelectedOptions.add(title);
                        }
                        onSelectedOptionsChange(newSelectedOptions);
                      }}
                    />
                  </SwiperItem>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          <ArrowButton
            color='primary'
            size='small'
            visibleCards={visibleCardsConfig}
            numberOfOptions={searchOptions.length}
            onClick={() => {
              swiper?.slideNext();
            }}
          >
            <StyledFontAwesomeIconMui5
              icon={isHorizontal ? faChevronRight : faChevronDown}
              color='inherit'
              sx={{ color: 'inherit' }}
            />
          </ArrowButton>
        </Box>
      </Box>
    </Box>
  );
};

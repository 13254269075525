import { DividerMui5 } from 'components/atoms/Divider';
import Image from 'next/image';
import GridMui5 from '@mui/material/Grid';
import LinkMui5 from '../../components/atoms/Link';
import TypographyMui5 from '@mui/material/Typography';
import NextLinkMui5 from '../../components/atoms/NextLink';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import useWidth from 'hooks/useWidth';
import { Post } from 'containers/blog/types';

function getImageSize(width): [number, number] {
  switch (width) {
    case 'md':
      return [175, 295];
    default:
      return [201, 340];
  }
}

export function BlogSection({
  posts,
  isShowMore = false,
  isTitleShown = true,
}: {
  posts: Post[];
  isShowMore?: boolean;
  isTitleShown?: boolean;
}): JSX.Element {
  const width = useWidth();
  const imapeParam = getImageSize(width);
  return (
    <>
      {isTitleShown && (
        <>
          <TypographyMui5 align='center' variant={'h4'} gutterBottom>
            Lease Like a Pro: Learn the Basics First
          </TypographyMui5>
          <DividerMui5 sx={{ marginBlock: 4 }} />
        </>
      )}
      <GridMui5 container spacing={1} justifyContent={'center'}>
        {posts?.map((post) => {
          return (
            <GridMui5
              item
              pb={{ sm: 1 }}
              sm={12}
              md={4}
              key={post.slug}
              textAlign={'center'}
            >
              <LinkMui5 href={`/blog/${post.slug}`} componant={NextLinkMui5}>
                <Image
                  height={imapeParam[0]}
                  width={imapeParam[1]}
                  src={post.image}
                  alt={post.altImageText ?? 'car'}
                />
                <TypographyMui5 fontWeight={'bold'} gutterBottom>
                  {post.title}
                </TypographyMui5>
              </LinkMui5>
            </GridMui5>
          );
        })}
      </GridMui5>
      {isShowMore && (
        <Box display={'flex'} justifyContent={'center'}>
          <StyledButtonMui5
            justifyContent={'center'}
            variant='outlined'
            color='primary'
            component={LinkMui5}
            componant={NextLinkMui5}
            href='/blog'
            disableElevation
            endIcon={
              <StyledFontAwesomeIconMui5 icon={faChevronDown} size='xs' />
            }
            sx={(theme) => ({
              '&.MuiButton-root': {
                marginTop: theme.spacing(4),
                minWidth: 176,
                maxWidth: 200,
              },
            })}
          >
            Show More
          </StyledButtonMui5>
        </Box>
      )}
    </>
  );
}

import {
  FC,
  ForwardRefRenderFunction,
  MouseEventHandler,
  forwardRef,
} from 'react';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import { StandardLonghandProperties } from 'csstype';
import Box from '@mui/material/Box';

type ThemeProps = {
  iconSize?: StandardLonghandProperties['height'];
  arrowColor?: StandardLonghandProperties['color'];
  triangleColor?: StandardLonghandProperties['color'];
};

const NavItemLeft = styled('div')(() => ({
  userSelect: 'none',
  zIndex: 1,
  marginTop: '-1.6rem',
  cursor: 'pointer',
  top: '50%',
  left: 0,
  position: 'absolute',
}));

const NavItemRight = styled('div')(() => ({
  userSelect: 'none',
  zIndex: 1,
  marginTop: '-1.6rem',
  cursor: 'pointer',
  top: '50%',
  right: 0,
  position: 'absolute',
}));

export const LeftNavIconSvg: FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox='0 0 52 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M-2.62433e-06 29.9811L52 0L52 60L-2.62433e-06 29.9811Z' />
      <path d='M38.0156 19.6875C38.2031 19.8281 38.2969 20.0156 38.2969 20.2031C38.2969 20.3906 38.2031 20.5781 38.0625 20.7188L29.5781 29.9531L38.0625 39.2344C38.3437 39.5156 38.3437 39.9844 38.0156 40.2656C37.7344 40.5469 37.2656 40.5469 36.9844 40.2188L27.9844 30.4688C27.7031 30.1875 27.7031 29.7656 27.9844 29.4844L36.9844 19.7344C37.2656 19.4063 37.7344 19.4063 38.0156 19.6875Z' />
    </svg>
  );
};

export const RightNavIconSvg: FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox='0 0 52 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M52 30.0189L0 60V0L52 30.0189Z' />
      <path d='M13.9844 40.3125C13.7969 40.1719 13.7031 39.9844 13.7031 39.7969C13.7031 39.6094 13.7969 39.4219 13.9375 39.2812L22.4219 30.0469L13.9375 20.7656C13.6562 20.4844 13.6562 20.0156 13.9844 19.7344C14.2656 19.4531 14.7344 19.4531 15.0156 19.7812L24.0156 29.5312C24.2969 29.8125 24.2969 30.2344 24.0156 30.5156L15.0156 40.2656C14.7344 40.5938 14.2656 40.5938 13.9844 40.3125Z' />
    </svg>
  );
};

export const LeftNavIconOriginal: ForwardRefRenderFunction<any, ThemeProps> = (
  themeProps,
  ref,
) => {
  const theme = useTheme();

  return (
    <NavItemLeft>
      <svg
        ref={ref}
        style={{
          height: '3.2rem',
          cursor: 'pointer',
        }}
        viewBox='0 0 52 60'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M-2.62433e-06 29.9811L52 0L52 60L-2.62433e-06 29.9811Z'
          fill={theme.palette.primary.main}
        />
        <path
          d='M38.0156 19.6875C38.2031 19.8281 38.2969 20.0156 38.2969 20.2031C38.2969 20.3906 38.2031 20.5781 38.0625 20.7188L29.5781 29.9531L38.0625 39.2344C38.3437 39.5156 38.3437 39.9844 38.0156 40.2656C37.7344 40.5469 37.2656 40.5469 36.9844 40.2188L27.9844 30.4688C27.7031 30.1875 27.7031 29.7656 27.9844 29.4844L36.9844 19.7344C37.2656 19.4063 37.7344 19.4063 38.0156 19.6875Z'
          fill={theme.palette.common.white}
        />
      </svg>
    </NavItemLeft>
  );
};

export const LeftNavIcon = forwardRef(LeftNavIconOriginal);

export const RightNavIconOriginal: ForwardRefRenderFunction<any, ThemeProps> = (
  themeProps,
  ref,
) => {
  const theme = useTheme();

  return (
    <NavItemRight>
      <svg
        ref={ref}
        style={{
          height: '3.2rem',
          cursor: 'pointer',
        }}
        viewBox='0 0 52 60'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M52 30.0189L0 60V0L52 30.0189Z'
          fill={theme.palette.primary.main}
        />
        <path
          d='M13.9844 40.3125C13.7969 40.1719 13.7031 39.9844 13.7031 39.7969C13.7031 39.6094 13.7969 39.4219 13.9375 39.2812L22.4219 30.0469L13.9375 20.7656C13.6562 20.4844 13.6562 20.0156 13.9844 19.7344C14.2656 19.4531 14.7344 19.4531 15.0156 19.7812L24.0156 29.5312C24.2969 29.8125 24.2969 30.2344 24.0156 30.5156L15.0156 40.2656C14.7344 40.5938 14.2656 40.5938 13.9844 40.3125Z'
          fill={theme.palette.common.white}
        />
      </svg>
    </NavItemRight>
  );
};

export const RightNavIcon = forwardRef(RightNavIconOriginal);

const NoHoverIconButton = styled(IconButton)({
  '&:hover': { backgroundColor: 'transparent' },
});

type CarouselCustomNavButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};
export const CarouselCustomNavButton: FC<CarouselCustomNavButtonProps> = ({
  onClick,
  children,
}) => (
  <Box display='flex' alignItems='center' height='100%'>
    <NoHoverIconButton onClick={onClick} disableRipple disableFocusRipple>
      {children}
    </NoHoverIconButton>
  </Box>
);

export const RightNavIconPure = styled(RightNavIconSvg)(({ theme }) => ({
  '&:hover path:first-of-type': {
    fill: theme.palette.primary.dark,
  },
  '& path:first-of-type': {
    fill: theme.palette.primary.main,
    transition: theme.transitions.create(['fill'], {
      duration: 250,
      easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
      delay: 0,
    }),
  },
  '& path:last-of-type': {
    fill: theme.palette.common.white,
  },
}));

export const LeftNavIconPure = styled(LeftNavIconSvg)(({ theme }) => ({
  '&:hover path:first-of-type': {
    fill: theme.palette.primary.dark,
  },
  '& path:first-of-type': {
    fill: theme.palette.primary.main,
    transition: theme.transitions.create(['fill'], {
      duration: 250,
      easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
      delay: 0,
    }),
  },
  '& path:last-of-type': {
    fill: theme.palette.common.white,
  },
}));

export enum GoogleBusinessAPIAccountType {
  ACCOUNT_TYPE_UNSPECIFIED = 'ACCOUNT_TYPE_UNSPECIFIED',
  PERSONAL = 'PERSONAL',
  LOCATION_GROUP = 'LOCATION_GROUP',
  USER_GROUP = 'USER_GROUP',
  ORGANIZATION = 'ORGANIZATION',
}

export enum GoogleBusinessAPIAccountRole {
  ACCOUNT_ROLE_UNSPECIFIED = 'ACCOUNT_ROLE_UNSPECIFIED',
  PRIMARY_OWNER = 'PRIMARY_OWNER',
  OWNER = 'OWNER',
  MANAGER = 'MANAGER',
  SITE_MANAGER = 'SITE_MANAGER',
}

export enum GoogleBusinessAPIVerificationState {
  VERIFICATION_STATE_UNSPECIFIED = 'VERIFICATION_STATE_UNSPECIFIED',
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
  VERIFICATION_REQUESTED = 'VERIFICATION_REQUESTED',
}

export enum GoogleBusinessAPIVettedState {
  VETTED_STATE_UNSPECIFIED = 'VETTED_STATE_UNSPECIFIED',
  NOT_VETTED = 'NOT_VETTED',
  VETTED = 'VETTED',
  INVALID = 'INVALID',
}

export enum GoogleBusinessAPIPermissionLevel {
  PERMISSION_LEVEL_UNSPECIFIED = 'PERMISSION_LEVEL_UNSPECIFIED',
  OWNER_LEVEL = 'OWNER_LEVEL',
  MEMBER_LEVEL = 'MEMBER_LEVEL',
}

export type GoogleBusinessAPIPostalAddress = {
  revision: number;
  regionCode: string;
  languageCode: string;
  postalCode: string;
  sortingCode: string;
  administrativeArea: string;
  locality: string;
  sublocality: string;
  addressLines: string[];
  recipients: string[];
  organization: string;
};

export type GoogleBusinessAPIOrganizationInfo = {
  registeredDomain: string;
  address: GoogleBusinessAPIPostalAddress;
  phoneNumber: string;
};

export type GoogleBusinessAPIAccount = {
  name: string;
  accountName: string;
  primaryOwner: string;
  type: GoogleBusinessAPIAccountType;
  role: GoogleBusinessAPIAccountRole;
  verificationState: GoogleBusinessAPIVerificationState;
  vettedState: GoogleBusinessAPIVettedState;
  accountNumber: string;
  permissionLevel: GoogleBusinessAPIPermissionLevel;
  organizationInfo: GoogleBusinessAPIOrganizationInfo;
};

export type GoogleBusinessAPIAccountsList = {
  accounts: GoogleBusinessAPIAccount[];
  nextPageToken: string;
};

export type GoogleBusinessAPIPhoneNumbers = {
  primaryPhone: string;
  additionalPhones: string[];
};

export type GoogleBusinessAPIServiceType = {
  serviceTypeId: string;
  displayName: string;
};

export type GoogleBusinessAPIMoreHoursType = {
  hoursTypeId: string;
  displayName: string;
  localizedDisplayName: string;
};

export type GoogleBusinessAPICategory = {
  name: string;
  displayName: string;
  serviceTypes: GoogleBusinessAPIServiceType[];
  moreHoursTypes: GoogleBusinessAPIMoreHoursType[];
};

export type GoogleBusinessAPICategories = {
  primaryCategory: GoogleBusinessAPICategory;
  additionalCategories: GoogleBusinessAPICategory[];
};

export enum GoogleBusinessAPIDayOfWeek {
  DAY_OF_WEEK_UNSPECIFIED = 'DAY_OF_WEEK_UNSPECIFIED',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export type GoogleBusinessAPITimeOfDay = {
  hours: number;
  minutes: number;
  seconds: number;
  nanos: number;
};

export type GoogleBusinessAPITimePeriod = {
  openDay: GoogleBusinessAPIDayOfWeek;
  openTime: GoogleBusinessAPITimeOfDay;
  closeDay: GoogleBusinessAPIDayOfWeek;
  closeTime: GoogleBusinessAPITimeOfDay;
};

export type GoogleBusinessAPIBusinessHours = {
  periods: GoogleBusinessAPITimePeriod[];
};

export type GoogleBusinessAPIDate = {
  year: number;
  month: number;
  day: number;
};

export enum GoogleBusinessAPIBusinessType {
  BUSINESS_TYPE_UNSPECIFIED = 'BUSINESS_TYPE_UNSPECIFIED',
  CUSTOMER_LOCATION_ONLY = 'CUSTOMER_LOCATION_ONLY',
  CUSTOMER_AND_BUSINESS_LOCATION = 'CUSTOMER_AND_BUSINESS_LOCATION',
}

export type GoogleBusinessAPIPlaceInfo = {
  placeName: string;
  placeId: string;
};

export type GoogleBusinessAPIPlaces = {
  placeInfos: GoogleBusinessAPIPlaceInfo[];
};

export type GoogleBusinessAPIServiceAreaBusiness = {
  businessType: GoogleBusinessAPIBusinessType;
  places: GoogleBusinessAPIPlaces;
  regionCode: string;
};

export type GoogleBusinessAPISpecialHourPeriod = {
  startDate: GoogleBusinessAPIDate;
  openTime: GoogleBusinessAPITimeOfDay;
  endDate: GoogleBusinessAPIDate;
  closeTime: GoogleBusinessAPITimeOfDay;
  closed: boolean;
};

export type GoogleBusinessAPISpecialHours = {
  specialHourPeriods: GoogleBusinessAPISpecialHourPeriod[];
};

export type GoogleBusinessAPIAdWordsLocationExtensions = {
  adPhone: string;
};

export type GoogleBusinessAPILatLng = {
  latitude: number;
  longitude: number;
};

export enum GoogleBusinessAPIOpenForBusiness {
  OPEN_FOR_BUSINESS_UNSPECIFIED = 'OPEN_FOR_BUSINESS_UNSPECIFIED',
  OPEN = 'OPEN',
  CLOSED_PERMANENTLY = 'CLOSED_PERMANENTLY',
  CLOSED_TEMPORARILY = 'CLOSED_TEMPORARILY',
}

export type GoogleBusinessAPIOpenInfo = {
  status: GoogleBusinessAPIOpenForBusiness;
  canReopen: boolean;
  openingDate: GoogleBusinessAPIDate;
};

export type GoogleBusinessAPIMetadata = {
  hasGoogleUpdated: boolean;
  hasPendingEdits: boolean;
  canDelete: boolean;
  canOperateLocalPost: boolean;
  canModifyServiceList: boolean;
  canHaveFoodMenus: boolean;
  canOperateHealthData: boolean;
  canOperateLodgingData: boolean;
  placeId: string;
  duplicateLocation: string;
  mapsUri: string;
  newReviewUri: string;
  canHaveBusinessCalls: boolean;
  hasVoiceOfMerchant: boolean;
};

export type GoogleBusinessAPIProfile = {
  description: string;
};

export enum GoogleBusinessAPIRelationType {
  RELATION_TYPE_UNSPECIFIED = 'RELATION_TYPE_UNSPECIFIED',
  DEPARTMENT_OF = 'DEPARTMENT_OF',
  INDEPENDENT_ESTABLISHMENT_IN = 'INDEPENDENT_ESTABLISHMENT_IN',
}

export type GoogleBusinessAPIRelevantLocation = {
  placeId: string;
  relationType: GoogleBusinessAPIRelationType;
};

export type GoogleBusinessAPIRelationshipData = {
  parentLocation: GoogleBusinessAPIRelevantLocation;
  childrenLocations: GoogleBusinessAPIRelevantLocation[];
  parentChain: string;
};

export type GoogleBusinessAPIMoreHours = {
  hoursTypeId: string;
  periods: GoogleBusinessAPITimePeriod[];
};

export type GoogleBusinessAPIMoney = {
  currencyCode: string;
  units: string;
  nanos: number;
};

export type GoogleBusinessAPIStructuredServiceItem = {
  serviceTypeId: string;
  description: string;
};

export type GoogleBusinessAPILabel = {
  displayName: string;
  description: string;
  languageCode: string;
};

export type GoogleBusinessAPIFreeFormServiceItem = {
  category: string;
  label: GoogleBusinessAPILabel;
};

export type GoogleBusinessAPIServiceItem = {
  price: GoogleBusinessAPIMoney;
  structuredServiceItem: GoogleBusinessAPIStructuredServiceItem;
  freeFormServiceItem: GoogleBusinessAPIFreeFormServiceItem;
};

export type GoogleBusinessAPILocation = {
  name: string;
  languageCode: string;
  storeCode: string;
  title: string;
  phoneNumbers: GoogleBusinessAPIPhoneNumbers;
  categories: GoogleBusinessAPICategories;
  storefrontAddress: GoogleBusinessAPIPostalAddress;
  websiteUri: string;
  regularHours: GoogleBusinessAPIBusinessHours;
  specialHours: GoogleBusinessAPISpecialHours;
  serviceArea: GoogleBusinessAPIServiceAreaBusiness;
  labels: string[];
  adWordsLocationExtensions: GoogleBusinessAPIAdWordsLocationExtensions;
  latlng: GoogleBusinessAPILatLng;
  openInfo: GoogleBusinessAPIOpenInfo;
  metadata: GoogleBusinessAPIMetadata;
  profile: GoogleBusinessAPIProfile;
  relationshipData: GoogleBusinessAPIRelationshipData;
  moreHours: GoogleBusinessAPIMoreHours[];
  serviceItems: GoogleBusinessAPIServiceItem[];
};

export type GoogleBusinessAPILocationsList = {
  locations: GoogleBusinessAPILocation[];
  nextPageToken: string;
  totalSize: number;
};

export type GoogleBusinessAPIReviewer = {
  profilePhotoUrl: string;
  displayName: string;
  isAnonymous: boolean;
};

export enum GoogleBusinessAPIStarRating {
  STAR_RATING_UNSPECIFIED = 'STAR_RATING_UNSPECIFIED',
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  FOUR = 'FOUR',
  FIVE = 'FIVE',
}

export type GoogleBusinessAPIReviewReply = {
  comment: string;
  updateTime: string;
};

export type GoogleBusinessAPIReview = {
  name: string;
  reviewId: string;
  reviewer: GoogleBusinessAPIReviewer;
  starRating: GoogleBusinessAPIStarRating;
  comment: string;
  createTime: string;
  updateTime: string;
  reviewReply: GoogleBusinessAPIReviewReply;
};

export type GoogleBusinessAPIReviewsList = {
  reviews: GoogleBusinessAPIReview[];
  averageRating: number;
  totalReviewCount: number;
  nextPageToken: string;
};

import React, { FC } from 'react';
import { StyledFontAwesomeIconMui5 } from '../../atoms/FontAwesomeIcon';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { styled } from '@mui/material/styles';
import { StandardLonghandProperties } from 'csstype';

type ThemeProps = {
  backgroundColor?: StandardLonghandProperties['color'];
  starColor?: StandardLonghandProperties['color'];
  starMargin?: StandardLonghandProperties['marginRight'];
  overlayWidth: StandardLonghandProperties['width'];
};

const GOLDEN_COLOR: StandardLonghandProperties['color'] = '#f8d448';

const StarContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: 'inline-flex',
  alignItems: 'center',
  position: 'relative',
}));

const StarOverlay = styled('div', {
  shouldForwardProp: (prop) => prop !== 'overlayWidth',
})<{ overlayWidth: string }>(({ overlayWidth }) => ({
  width: overlayWidth,
  backgroundColor: 'black',
  opacity: 0.7,
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
  '@supports (mix-blend-mode: color)': {
    '&': {
      mixBlendMode: 'color',
      opacity: 'unset',
    },
  },
}));

const StarIcon = styled(StyledFontAwesomeIconMui5)(({ theme }) => ({
  marginRight: theme.spacing(0.1),
  color: GOLDEN_COLOR,
  fill: GOLDEN_COLOR,
  display: 'flex',
  '&:last-of-type': {
    marginRight: 0,
  },
}));

type Props = { rating: number; maxRating: number } & Omit<
  ThemeProps,
  'overlayWidth'
>;

export const StarRatingComponent: FC<Props> = (props) => {
  const { rating, maxRating } = props;
  const percentage = Math.round((rating / maxRating) * 100);
  const overlayWidth = `${100 - percentage}%`;

  return (
    <StarContainer>
      {Array.from(Array(maxRating).keys()).map((_, key) => (
        <StarIcon icon={faStar} key={key} />
      ))}
      <StarOverlay overlayWidth={overlayWidth} />
    </StarContainer>
  );
};

import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system';
import { FC, useState } from 'react';
import { StyledFontAwesomeIconMui5 } from './FontAwesomeIcon';
import { StyledButtonMui5 } from './StyledButton';

const viewButtonStyles: SxProps<ThemeMui5> = (theme) => ({
  '&.MuiButton-root': {
    marginTop: theme.spacing(4),
    minWidth: 176,
    maxWidth: 200,
  },
});

type ShowMoreButtonProps = {
  onClick: (expanded: boolean) => void;
};

export const ShowMoreButton: FC<ShowMoreButtonProps> = ({ onClick }) => {
  const [showMoreExpanded, toggleShowMoreExpanded] = useState(false);

  return (
    <StyledButtonMui5
      onClick={() => {
        onClick(showMoreExpanded);
        toggleShowMoreExpanded(!showMoreExpanded);
      }}
      variant='outlined'
      color='primary'
      disableElevation
      endIcon={
        <StyledFontAwesomeIconMui5
          icon={showMoreExpanded ? faChevronUp : faChevronDown}
          size='xs'
        />
      }
      sx={viewButtonStyles}
    >
      Show {showMoreExpanded ? 'Less' : 'More'}
    </StyledButtonMui5>
  );
};

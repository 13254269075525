import { ElementType } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const Subheader = styled(Typography)<{ component?: ElementType }>(
  ({ theme }) => ({
    '&.MuiTypography-root': {
      textTransform: 'uppercase',
      fontWeight: 600,
      marginBottom: theme.spacing(2),
      fontSize: '1rem',
      lineHeight: 1.5,
    },
  }),
);
